import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridEventListener,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import { ethers } from "ethers";
import { useHistory } from "react-router-dom";

import {
  balanceApi,
  transactionExchangeRateApi,
} from "services/proxy/axiosClient";
import { BlockchainTransactionDto, WalletBalanceVm } from "services/proxy";
import useSessionStorage from "utils/tempStorage";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

const columns: GridColDef[] = [
  {
    field: "addressFrom",
    headerName: "From Address",
    width: 380,
  },
  { field: "addressTo", headerName: "To Address", width: 380 },
  {
    field: "formattedDateValue",
    headerName: "Transaction Date",
    width: 250,
  },
  { field: "type", headerName: "Type", width: 60 },
  { field: "hash", headerName: "Transaction Hash", width: 350 },
  { field: "blockNumber", headerName: "Block Number", width: 120 },
  { field: "contractAddress", headerName: "Contract Address", width: 380 },
  { field: "fiatValue", headerName: "FIAT Amount", width: 120 },
  { field: "qoinValue", headerName: "Qoin Amount", width: 120 },
];

export const TransactionsDataComponent = () => {
  const { getToken } = useKindeAuth();
  window.analytics.page("Transaction Grid Page");
  let history = useHistory();
  // get and set token.

  // Store token and hash in temp storage
  const [transactionHash, setTransactionHash] = useSessionStorage(
    "transactionHash",
    null
  );

  //Sets the wallet
  const [walletAddress, setWalletAddress] = useSessionStorage(
    "walletAddress",
    ""
  );

  // Sets the grid data
  const [gridData, setGridData] = useState<BlockchainTransactionDto[]>([]);
  const [balanceData, setBalanceData] = useState<WalletBalanceVm>();
  const [errorText, setErrorText] = useState(" ");
  const [gridLoading, setGridLoading] = useState(false);
  const [isValidAddress, setIsValidAddress] = useState(true);
  const [sessionCurrency, setSessionCurrency] = useSessionStorage(
    "sessionCurrency",
    "AUD"
  );
  const [currency, setCurrency] = useState(sessionCurrency);
  const [currencySymbol, setCurrencySymbol] = useState("$");

  const fetchData = async () => {
    const token = await getToken();
    if (walletAddress) {
      setGridLoading(true);
      const exchangeRates = await transactionExchangeRateApi(
        token ?? ""
      ).transactionExchangeRates(walletAddress, currency);
      setGridData(exchangeRates.data);
      if (exchangeRates.status === 200) {
        setGridLoading(false);
      }

      const accountBalance = await balanceApi(token ?? "").getBalance(
        walletAddress,
        currency
      );
      setBalanceData(accountBalance.data);
    }
  };

  useEffect(() => {
    console.log("fetchData", walletAddress);
    fetchData().catch(console.error);
  }, [walletAddress, currency]);

  const handleChange = (event: SelectChangeEvent) => {
    setSessionCurrency(event.target.value as string);
    setCurrency(event.target.value as string);
    let symbol = "$";
    switch (event.target.value.toUpperCase()) {
      case "ZAR":
        symbol = "R";
        break;
      case "GBP":
        symbol = "£";
        break;
      case "AED":
        symbol = "د.إ";
        break;
      case "EUR":
        symbol = "£";
        break;
      case "AUD":
      case "NZD":
      case "USD":
      case "SGD":
      default:
        symbol = "$";
    }
    setCurrencySymbol(symbol);
  };

  const validateWalletAddress = (value) => {
    if (ethers.utils.isAddress(value)) {
      setWalletAddress(value);
      setErrorText(" ");
      setIsValidAddress(true);
    } else {
      setErrorText("Please enter a valid Qoin wallet address");
      setIsValidAddress(false);
    }
  };

  const handleEvent: GridEventListener<"rowClick"> = (params) => {
    console.log("params", params);
    console.log("txn hash", params?.id);
    setTransactionHash(params?.id);
    history.push("/transaction-detail");
  };

  return (
    <>
      <TextField
        required
        error={errorText !== " "}
        helperText={errorText}
        id="outlined-basic"
        label="Qoin wallet address"
        variant="outlined"
        style={{ width: "100%" }}
        defaultValue={walletAddress}
        onChange={(event) => validateWalletAddress(event.target.value)}
      />
      <br></br>

      {!isValidAddress && (
        <Button disabled variant="contained" color="primary">
          Search
        </Button>
      )}
      {isValidAddress && (
        <Button variant="contained" color="primary" onClick={fetchData}>
          Search
        </Button>
      )}
      <br></br>
      <br></br>
      <Box sx={{ minWidth: 240 }}>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Currency</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currency}
            label="Currency"
            onChange={handleChange}
          >
            <MenuItem value={"AUD"}>AUD</MenuItem>
            <MenuItem value={"AED"}>AED</MenuItem>
            <MenuItem value={"EUR"}>EUR</MenuItem>
            <MenuItem value={"NZD"}>NZD</MenuItem>
            <MenuItem value={"USD"}>USD</MenuItem>
            <MenuItem value={"GBP"}>GBP</MenuItem>
            <MenuItem value={"ZAR"}>ZAR</MenuItem>
            <MenuItem value={"SGD"}>SGD</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          {balanceData?.qoinAmount && (
            <Typography
              sx={{ fontSize: 18 }}
              color="text.secondary"
              gutterBottom
            >
              Qoin {balanceData?.qoinAmount}
            </Typography>
          )}
          {balanceData?.currencyAmount && (
            <Typography
              sx={{ fontSize: 18 }}
              color="text.secondary"
              gutterBottom
            >
              {currencySymbol} {balanceData?.currencyAmount}
            </Typography>
          )}
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {walletAddress}
          </Typography>
        </CardContent>
      </Card>
      <br></br>
      <br></br>

      {/* 838px */}
      <div style={{ height: "72.4%", width: "100%" }}>
        <DataGridPro
          columnVisibilityModel={{
            id: false,
          }}
          getRowId={(row) => row.hash}
          onRowClick={handleEvent}
          componentsProps={{
            toolbar: { printOptions: { disableToolbarButton: true } },
          }}
          rows={gridData}
          components={{ Toolbar: GridToolbar }}
          columns={columns}
          loading={gridLoading}
        />
      </div>
    </>
  );
};
