import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";

import Loading from "../components/Loading";

import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { get } from "http";
export const ProfileComponent = () => {
  window.analytics.page("Profile Page");
  const [token, setToken] = useState<string | undefined>(undefined);
  const [idToken, setIdToken] = useState<string | undefined>(undefined);
  const { getToken, getUser, getIdToken } = useKindeAuth();

  useEffect(() => {
    async function fetchToken() {
      const atoken = await getToken();
      const itoken = await getIdToken();
      setToken(atoken);
      setIdToken(itoken);
    }
    fetchToken();
  }, []);

  console.log("token", token);
  console.log("idtoken", idToken);

  const user = getUser();
  const profileName = `${user?.given_name?.substring(
    0,
    1
  )}${user?.family_name?.substring(0, 1)}`;
  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <span className="rounded-circle img-fluid profile-picture mb-3 mb-md- w-5">
            {profileName}
          </span>
          {/* <img
            src={user?.picture ?? ""}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          /> */}
        </Col>
        <Col md>
          <h2>{user?.given_name}</h2>
          <p className="lead text-muted">{user?.email}</p>
        </Col>
      </Row>
    </Container>
  );
};
