import React, { Fragment } from "react";

import Hero from "../components/Hero";

const Home = () => {
  window.analytics.page("Home Page");

  return (
    <Fragment>
      <Hero />
      {/* <hr/>
        <Content/> */}
    </Fragment>
  );
};

export default Home;
