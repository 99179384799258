import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";

import Home from "./views/Home";
import { ProfileComponent } from "./views/Profile";
import { TransactionsDataComponent } from "./views/TransactionGrid";

import history from "./utils/history";

import { createTheme, ThemeProvider } from "@mui/material/styles";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import { TransactionDetailComponent } from "views/TransactionDetail";
import { LicenseInfo } from "@mui/x-license-pro";

import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import ProtectedRoute from "ProtectedRoute";

initFontAwesome();

declare global {
  interface Window {
    analytics: any;
  }
}

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY || "");

const App = () => {
  const { isLoading, error } = useKindeAuth();
  window.analytics.page("App Loaded");

  if (error) {
    return <div>Oops... {error}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: "#197a83",
      },
      secondary: {
        main: "#ff7961",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <div id="app" className="d-flex flex-column h-100">
          <NavBar />
          <Container className="flex-grow-1 mt-5">
            <Switch>
              <Route path="/" exact component={Home} />
              <ProtectedRoute path="/profile">
                <ProfileComponent />
              </ProtectedRoute>

              <ProtectedRoute path="/transactions">
                <TransactionsDataComponent />
              </ProtectedRoute>
              <ProtectedRoute
                path="/transaction-detail"
                component={TransactionDetailComponent}
              >
                <TransactionDetailComponent />
              </ProtectedRoute>
            </Switch>
          </Container>
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
