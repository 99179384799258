import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { KindeProvider } from "@kinde-oss/kinde-auth-react";

import { getConfig } from "./config";

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,
};

ReactDOM.render(
  <KindeProvider
    clientId={providerConfig.clientId}
    domain={providerConfig.domain}
    audience={providerConfig.audience}
    logoutUri={window.location.origin}
    redirectUri={window.location.origin}
  >
    <App />
  </KindeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
