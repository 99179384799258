import React from "react";
import loading from "../assets/loading.svg";

const Loading = () => (
    <div className="spinner">
        <img src={loading} alt="Loading" width={250} height={250}/>
    </div>
);

export default Loading;
