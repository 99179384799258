import { Route, Redirect } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

const ProtectedRoute = ({ children, ...rest }) => {
  const { isAuthenticated } = useKindeAuth();

  return (
    <Route {...rest}>
      {isAuthenticated ? (
        children // Render the protected component if authenticated
      ) : (
        <Redirect to="/" replace /> // Redirect to login if not
      )}
    </Route>
  );
};

export default ProtectedRoute;
