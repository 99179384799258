import React, { useEffect, useState } from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import Loading from "../components/Loading";
import { Button, TableBody, TableCell, TableRow } from "@mui/material";
import { Table } from "reactstrap";
import useSessionStorage from "utils/tempStorage";
import { BlockchainTransactionDto } from "services/proxy";
import { transactionApi } from "services/proxy/axiosClient";
import { useHistory } from "react-router-dom";

export const TransactionDetailComponent = () => {
  const { getToken } = useKindeAuth();

  window.analytics.page("Transaction Detail Page");

  const history = useHistory();

  function handleTransactionClick() {
    history.push("/transactions");
  }

  const [sessionCurrency, setSessionCurrency] = useSessionStorage(
    "sessionCurrency",
    "AUD"
  );
  const [transactionHash, setTransactionHash] = useSessionStorage(
    "transactionHash",
    null
  );
  let rowObject: BlockchainTransactionDto = {};
  const [transactionDetail, setTransactionDetail] =
    useState<BlockchainTransactionDto>(rowObject);

  useEffect(() => {
    async function fetchTokenAndData() {
      const token = await getToken();
      if (transactionHash) {
        const response = await transactionApi(token ?? "").getTransaction(
          transactionHash,
          sessionCurrency
        );
        setTransactionDetail(response.data);
      }
    }

    fetchTokenAndData().catch(console.error);
  }, [transactionHash]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleTransactionClick}
      >
        Back
      </Button>
      <br></br>
      <br></br>
      <h4 className="mb-4">Transaction Details</h4>
      <div className="App" style={{ minHeight: "1200px" }}>
        <div className="table-container">
          <Table style={{ width: 650 }}>
            <TableBody>
              <TableRow className="row-style">
                <TableCell variant="head" style={{ minWidth: "200px" }}>
                  Transaction Hash
                </TableCell>
                <TableCell>{transactionDetail.hash}</TableCell>
              </TableRow>
              <TableRow className="row-style">
                <TableCell variant="head">Block Number</TableCell>
                <TableCell>{transactionDetail.blockNumber}</TableCell>
              </TableRow>
              <TableRow className="row-style">
                <TableCell variant="head">Address From</TableCell>
                <TableCell>{transactionDetail.addressFrom}</TableCell>
              </TableRow>
              <TableRow className="row-style">
                <TableCell variant="head">Address To</TableCell>
                <TableCell>{transactionDetail.addressTo}</TableCell>
              </TableRow>
              <TableRow className="row-style">
                <TableCell variant="head">Qoin Amount</TableCell>
                <TableCell>{transactionDetail.value}</TableCell>
              </TableRow>
              <TableRow className="row-style">
                <TableCell variant="head">
                  Fiat Value({sessionCurrency})
                </TableCell>
                <TableCell>{transactionDetail.fiatValue}</TableCell>
              </TableRow>
              <TableRow className="row-style">
                <TableCell variant="head">Block Hash</TableCell>
                <TableCell>{transactionDetail.blockHash}</TableCell>
              </TableRow>
              <TableRow className="row-style">
                <TableCell variant="head">Transaction Date</TableCell>
                <TableCell>{transactionDetail.formattedDateValue}</TableCell>
              </TableRow>
              <TableRow className="row-style">
                <TableCell variant="head">Transaction Index</TableCell>
                <TableCell>{transactionDetail.transactionIndex}</TableCell>
              </TableRow>
              <TableRow className="row-style">
                <TableCell variant="head">Transaction Receipt Status</TableCell>
                <TableCell>
                  {transactionDetail.transactionReceiptStatus === 1
                    ? "Transaction Completed"
                    : "Transaction Failed"}
                </TableCell>
              </TableRow>
              <TableRow className="row-style">
                <TableCell variant="head">Transaction Status</TableCell>
                <TableCell>
                  {transactionDetail.failed === true
                    ? "Transaction Failed"
                    : "Transaction Succeeded"}
                </TableCell>
              </TableRow>
              {/* <TableRow className="row-style">
                <TableCell variant="head">Input</TableCell>
                <TableCell>TODO: input</TableCell>
              </TableRow> */}
              {/* <TableRow className="row-style">
                <TableCell variant="head">Decoded Input</TableCell> */}
              {/* <TableCell>{decodedInputValue}</TableCell> */}
              {/* <TableCell>
                  {transactionDetail?.decodedInput
                    ?.split("|")
                    .map((instruction, index, a) => {
                      return <div>{instruction}</div>;
                    })}
                </TableCell> */}
              {/* </TableRow> */}
              {/* <TableRow className="row-style">
                <TableCell variant="head">Nonce</TableCell>
                <TableCell>{transactionDetail.nonce}</TableCell>
              </TableRow> */}
              <TableRow className="row-style">
                <TableCell variant="head">Contract Address</TableCell>
                <TableCell>{transactionDetail.contractAddress}</TableCell>
              </TableRow>
              {/* <TableRow className="row-style">
                <TableCell variant="head">Method Id</TableCell>
                <TableCell>{transactionDetail.methodId}</TableCell>
              </TableRow>
              <TableRow className="row-style">
                <TableCell variant="head">Function Name</TableCell>
                <TableCell>{transactionDetail.functionName}</TableCell>
              </TableRow> */}
              {/* <TableRow className="row-style">
                <TableCell variant="head">Logs</TableCell>
                <TableCell>
                  <Highlight>TODO: RAW LOGS</Highlight>
                </TableCell>
              </TableRow> */}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
};
