import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import "./styling.css";
import { ethers } from "ethers";
import { useHistory } from "react-router-dom";

import useSessionStorage from "utils/tempStorage";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

const logo = "https://bpspublic.blob.core.windows.net/images/qoin-app-logo.png";

const Hero = () => {
  const { isAuthenticated, login, getToken } = useKindeAuth();
  const [accessToken, setAccessToken] = useState<string>();
  const [walletAddress, setWalletAddress] = useSessionStorage(
    "walletAddress",
    ""
  );
  const [errorText, setErrorText] = useState(" ");
  const [isValidAddress, setIsValidAddress] = useState(false);
  // Store token in temp storage
  const validateInput = (value) => {
    if (ethers.utils.isAddress(value)) {
      // setValue(value);
      setWalletAddress(value);
      setErrorText(" ");
      setIsValidAddress(true);
    } else {
      setErrorText("Please enter a valid Qoin wallet address");
      setIsValidAddress(false);
    }
    // Validate transaction hash - /^0x([A-Fa-f0-9]{64})$/
  };
  const history = useHistory();

  console.log("process.env.NODE_ENV ", process.env.NODE_ENV);

  function handleTransactionClick() {
    history.push("/transactions");
  }

  useEffect(() => {
    async function fetchToken() {
      const token = await getToken();
      console.log("token", token);
      setAccessToken(token);
    }
    fetchToken();
  }, []);

  return (
    <div className="text-center hero my-5">
      <img className="mb-3 app-logo" src={logo} alt="React logo" width="80" />
      <h4 className="mb-4">Download Qoin Wallet Transactions</h4>
      {isAuthenticated && (
        <TextField
          required
          error={errorText !== " "}
          helperText={errorText}
          id="outlined-basic"
          label="Qoin wallet address"
          variant="outlined"
          style={{ width: "100%" }}
          onChange={(event) => validateInput(event.target.value)}
        />
      )}

      <br></br>
      <br></br>

      {!isValidAddress && isAuthenticated && (
        <Button disabled variant="contained" color="primary">
          Search
        </Button>
      )}
      {isValidAddress && isAuthenticated && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleTransactionClick}
        >
          Search
        </Button>
      )}
      {!isAuthenticated && (
        <>
          <Button
            id="qsLoginBtn"
            variant="contained"
            color="primary"
            className="btn-margin"
            onClick={() => {
              login();
            }}
          >
            Log in
          </Button>

          <br></br>
          <br></br>
          <br></br>
          <p style={{ fontSize: "12px" }}>
            By accessing the Qoin Block Explorer on the Qoin website, you agree
            to be bound by the Qoin Terms of Use, the Qoin Privacy Policy, the
            Product Disclosure Statement and the Financial Services Guide.
          </p>

          <p style={{ fontSize: "12px" }}>
            If you would like to review any of these documents, please click on
            the following link{" "}
            <a
              href="https://support.qoin.world/knowledge-base/wallet-terms-of-use/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#197a83" }}
            >
              here
            </a>
          </p>
        </>
      )}
    </div>
  );
};

export default Hero;
