/* tslint:disable */
/* eslint-disable */
/**
 * Qoin-Block-Explorer
 * Qoin-Block-Explorer API
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BlockchainTransactionDto
 */
export interface BlockchainTransactionDto {
    /**
     * 
     * @type {string}
     * @memberof BlockchainTransactionDto
     */
    'blockHash'?: string;
    /**
     * 
     * @type {number}
     * @memberof BlockchainTransactionDto
     */
    'blockNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof BlockchainTransactionDto
     */
    'hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockchainTransactionDto
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof BlockchainTransactionDto
     */
    'utcTimeStamp'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BlockchainTransactionDto
     */
    'transactionIndex'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BlockchainTransactionDto
     */
    'transactionReceiptStatus'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof BlockchainTransactionDto
     */
    'failed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BlockchainTransactionDto
     */
    'gasUsed'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BlockchainTransactionDto
     */
    'cumulativeGasUsed'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BlockchainTransactionDto
     */
    'effectiveGasPrice'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BlockchainTransactionDto
     */
    'hasLog'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BlockchainTransactionDto
     */
    'gas'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BlockchainTransactionDto
     */
    'gasPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BlockchainTransactionDto
     */
    'nonce'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BlockchainTransactionDto
     */
    'contractAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockchainTransactionDto
     */
    'maxFeePerGas'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockchainTransactionDto
     */
    'maxPriorityFeePerGas'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockchainTransactionDto
     */
    'addressFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockchainTransactionDto
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockchainTransactionDto
     */
    'addressTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockchainTransactionDto
     */
    'methodId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockchainTransactionDto
     */
    'functionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockchainTransactionDto
     */
    'decodedInput'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockchainTransactionDto
     */
    'fiatValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockchainTransactionDto
     */
    'qoinValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockchainTransactionDto
     */
    'formattedDateValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockchainTransactionDto
     */
    'partitionKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockchainTransactionDto
     */
    'rowKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockchainTransactionDto
     */
    'timestamp'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof BlockchainTransactionDto
     */
    'eTag'?: object;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProblemDetails
     */
    'extensions'?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'statusCode'?: number;
}
/**
 * 
 * @export
 * @interface WalletBalanceVm
 */
export interface WalletBalanceVm {
    /**
     * 
     * @type {string}
     * @memberof WalletBalanceVm
     */
    'qoinAmount'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletBalanceVm
     */
    'currencyAmount'?: string;
}
/**
 * 
 * @export
 * @interface WalletTransactionByAddress
 */
export interface WalletTransactionByAddress {
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'blockNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'timeStamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'hash'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'nonce'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'blockHash'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'transactionIndex'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'to'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'gas'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'gasPrice'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'isError'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'txreceipt_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'input'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'contractAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'cumulativeGasUsed'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'gasUsed'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'confirmations'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'methodId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'functionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'tokenName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'tokenSymbol'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionByAddress
     */
    'tokenDecimal'?: string;
    /**
     * 
     * @type {number}
     * @memberof WalletTransactionByAddress
     */
    'events'?: number;
}
/**
 * 
 * @export
 * @interface WalletTransactionsByAddress
 */
export interface WalletTransactionsByAddress {
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionsByAddress
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletTransactionsByAddress
     */
    'message'?: string;
    /**
     * 
     * @type {Array<WalletTransactionByAddress>}
     * @memberof WalletTransactionsByAddress
     */
    'result'?: Array<WalletTransactionByAddress>;
}

/**
 * BalanceApi - axios parameter creator
 * @export
 */
export const BalanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a specific wallet balance given wallet address.
         * @summary Get a wallet balance for given wallet
         * @param {string} walletAddress a wallet address
         * @param {string} currency a FIAT currency e.g. AUD NZD etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalance: async (walletAddress: string, currency: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletAddress' is not null or undefined
            assertParamExists('getBalance', 'walletAddress', walletAddress)
            // verify required parameter 'currency' is not null or undefined
            assertParamExists('getBalance', 'currency', currency)
            const localVarPath = `/Balance/GetBalance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (walletAddress !== undefined) {
                localVarQueryParameter['walletAddress'] = walletAddress;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BalanceApi - functional programming interface
 * @export
 */
export const BalanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BalanceApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a specific wallet balance given wallet address.
         * @summary Get a wallet balance for given wallet
         * @param {string} walletAddress a wallet address
         * @param {string} currency a FIAT currency e.g. AUD NZD etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBalance(walletAddress: string, currency: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletBalanceVm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBalance(walletAddress, currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BalanceApi - factory interface
 * @export
 */
export const BalanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BalanceApiFp(configuration)
    return {
        /**
         * Returns a specific wallet balance given wallet address.
         * @summary Get a wallet balance for given wallet
         * @param {string} walletAddress a wallet address
         * @param {string} currency a FIAT currency e.g. AUD NZD etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalance(walletAddress: string, currency: string, options?: any): AxiosPromise<WalletBalanceVm> {
            return localVarFp.getBalance(walletAddress, currency, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BalanceApi - object-oriented interface
 * @export
 * @class BalanceApi
 * @extends {BaseAPI}
 */
export class BalanceApi extends BaseAPI {
    /**
     * Returns a specific wallet balance given wallet address.
     * @summary Get a wallet balance for given wallet
     * @param {string} walletAddress a wallet address
     * @param {string} currency a FIAT currency e.g. AUD NZD etc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public getBalance(walletAddress: string, currency: string, options?: AxiosRequestConfig) {
        return BalanceApiFp(this.configuration).getBalance(walletAddress, currency, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TransactionApi - axios parameter creator
 * @export
 */
export const TransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a specific transaction for a given transaction hash.
         * @summary Get a specific transaction for a given transaction hash
         * @param {string} hash a transaction hash
         * @param {string} currency a FIAT currency e.g. AUD NZD etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction: async (hash: string, currency: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('getTransaction', 'hash', hash)
            // verify required parameter 'currency' is not null or undefined
            assertParamExists('getTransaction', 'currency', currency)
            const localVarPath = `/Transaction/GetTransaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (hash !== undefined) {
                localVarQueryParameter['hash'] = hash;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionApi - functional programming interface
 * @export
 */
export const TransactionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a specific transaction for a given transaction hash.
         * @summary Get a specific transaction for a given transaction hash
         * @param {string} hash a transaction hash
         * @param {string} currency a FIAT currency e.g. AUD NZD etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransaction(hash: string, currency: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockchainTransactionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransaction(hash, currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionApi - factory interface
 * @export
 */
export const TransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionApiFp(configuration)
    return {
        /**
         * Returns a specific transaction for a given transaction hash.
         * @summary Get a specific transaction for a given transaction hash
         * @param {string} hash a transaction hash
         * @param {string} currency a FIAT currency e.g. AUD NZD etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction(hash: string, currency: string, options?: any): AxiosPromise<BlockchainTransactionDto> {
            return localVarFp.getTransaction(hash, currency, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionApi - object-oriented interface
 * @export
 * @class TransactionApi
 * @extends {BaseAPI}
 */
export class TransactionApi extends BaseAPI {
    /**
     * Returns a specific transaction for a given transaction hash.
     * @summary Get a specific transaction for a given transaction hash
     * @param {string} hash a transaction hash
     * @param {string} currency a FIAT currency e.g. AUD NZD etc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public getTransaction(hash: string, currency: string, options?: AxiosRequestConfig) {
        return TransactionApiFp(this.configuration).getTransaction(hash, currency, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TransactionExchangeRatesApi - axios parameter creator
 * @export
 */
export const TransactionExchangeRatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of transaction exchange rates for a wallet.
         * @summary Get all transactions for a given wallet address
         * @param {string} walletAddress a wallet address
         * @param {string} currency a FIAT currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionExchangeRates: async (walletAddress: string, currency: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletAddress' is not null or undefined
            assertParamExists('transactionExchangeRates', 'walletAddress', walletAddress)
            // verify required parameter 'currency' is not null or undefined
            assertParamExists('transactionExchangeRates', 'currency', currency)
            const localVarPath = `/Transactions/GetTransactionsWithExchangeRates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (walletAddress !== undefined) {
                localVarQueryParameter['walletAddress'] = walletAddress;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionExchangeRatesApi - functional programming interface
 * @export
 */
export const TransactionExchangeRatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionExchangeRatesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of transaction exchange rates for a wallet.
         * @summary Get all transactions for a given wallet address
         * @param {string} walletAddress a wallet address
         * @param {string} currency a FIAT currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionExchangeRates(walletAddress: string, currency: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlockchainTransactionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionExchangeRates(walletAddress, currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionExchangeRatesApi - factory interface
 * @export
 */
export const TransactionExchangeRatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionExchangeRatesApiFp(configuration)
    return {
        /**
         * Returns a list of transaction exchange rates for a wallet.
         * @summary Get all transactions for a given wallet address
         * @param {string} walletAddress a wallet address
         * @param {string} currency a FIAT currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionExchangeRates(walletAddress: string, currency: string, options?: any): AxiosPromise<Array<BlockchainTransactionDto>> {
            return localVarFp.transactionExchangeRates(walletAddress, currency, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionExchangeRatesApi - object-oriented interface
 * @export
 * @class TransactionExchangeRatesApi
 * @extends {BaseAPI}
 */
export class TransactionExchangeRatesApi extends BaseAPI {
    /**
     * Returns a list of transaction exchange rates for a wallet.
     * @summary Get all transactions for a given wallet address
     * @param {string} walletAddress a wallet address
     * @param {string} currency a FIAT currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionExchangeRatesApi
     */
    public transactionExchangeRates(walletAddress: string, currency: string, options?: AxiosRequestConfig) {
        return TransactionExchangeRatesApiFp(this.configuration).transactionExchangeRates(walletAddress, currency, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TransactionsApi - axios parameter creator
 * @export
 */
export const TransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of transactions for a wallet.
         * @summary Get all transactions for a given wallet address
         * @param {string} walletAddress a  wallet address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions: async (walletAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletAddress' is not null or undefined
            assertParamExists('getTransactions', 'walletAddress', walletAddress)
            const localVarPath = `/Transactions/GetTransactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (walletAddress !== undefined) {
                localVarQueryParameter['walletAddress'] = walletAddress;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionsApi - functional programming interface
 * @export
 */
export const TransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of transactions for a wallet.
         * @summary Get all transactions for a given wallet address
         * @param {string} walletAddress a  wallet address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactions(walletAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlockchainTransactionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactions(walletAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionsApi - factory interface
 * @export
 */
export const TransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionsApiFp(configuration)
    return {
        /**
         * Returns a list of transactions for a wallet.
         * @summary Get all transactions for a given wallet address
         * @param {string} walletAddress a  wallet address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions(walletAddress: string, options?: any): AxiosPromise<Array<BlockchainTransactionDto>> {
            return localVarFp.getTransactions(walletAddress, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionsApi - object-oriented interface
 * @export
 * @class TransactionsApi
 * @extends {BaseAPI}
 */
export class TransactionsApi extends BaseAPI {
    /**
     * Returns a list of transactions for a wallet.
     * @summary Get all transactions for a given wallet address
     * @param {string} walletAddress a  wallet address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public getTransactions(walletAddress: string, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).getTransactions(walletAddress, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WalletTransactionsApi - axios parameter creator
 * @export
 */
export const WalletTransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of transactions for a wallet.
         * @summary Get all transactions for a given wallet address
         * @param {string} module request type - in this case account
         * @param {string} action request type e.g. tokentx or txlist
         * @param {string} address a wallet address
         * @param {string} contractaddress a contract address
         * @param {string} startblock block to start return transactions from
         * @param {string} endblock last block number to return transactions for
         * @param {string} page page to start returning transactions for
         * @param {string} offset offset before next page is to be returned
         * @param {string} sort sort order of the transactions
         * @param {string} apikey auth0 JWT key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletTransactions: async (module: string, action: string, address: string, contractaddress: string, startblock: string, endblock: string, page: string, offset: string, sort: string, apikey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'module' is not null or undefined
            assertParamExists('getWalletTransactions', 'module', module)
            // verify required parameter 'action' is not null or undefined
            assertParamExists('getWalletTransactions', 'action', action)
            // verify required parameter 'address' is not null or undefined
            assertParamExists('getWalletTransactions', 'address', address)
            // verify required parameter 'contractaddress' is not null or undefined
            assertParamExists('getWalletTransactions', 'contractaddress', contractaddress)
            // verify required parameter 'startblock' is not null or undefined
            assertParamExists('getWalletTransactions', 'startblock', startblock)
            // verify required parameter 'endblock' is not null or undefined
            assertParamExists('getWalletTransactions', 'endblock', endblock)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getWalletTransactions', 'page', page)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('getWalletTransactions', 'offset', offset)
            // verify required parameter 'sort' is not null or undefined
            assertParamExists('getWalletTransactions', 'sort', sort)
            // verify required parameter 'apikey' is not null or undefined
            assertParamExists('getWalletTransactions', 'apikey', apikey)
            const localVarPath = `/WalletTransactions/GetWalletTransactionsv1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer_auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (module !== undefined) {
                localVarQueryParameter['module'] = module;
            }

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }

            if (address !== undefined) {
                localVarQueryParameter['address'] = address;
            }

            if (contractaddress !== undefined) {
                localVarQueryParameter['contractaddress'] = contractaddress;
            }

            if (startblock !== undefined) {
                localVarQueryParameter['startblock'] = startblock;
            }

            if (endblock !== undefined) {
                localVarQueryParameter['endblock'] = endblock;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (apikey !== undefined) {
                localVarQueryParameter['apikey'] = apikey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WalletTransactionsApi - functional programming interface
 * @export
 */
export const WalletTransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WalletTransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of transactions for a wallet.
         * @summary Get all transactions for a given wallet address
         * @param {string} module request type - in this case account
         * @param {string} action request type e.g. tokentx or txlist
         * @param {string} address a wallet address
         * @param {string} contractaddress a contract address
         * @param {string} startblock block to start return transactions from
         * @param {string} endblock last block number to return transactions for
         * @param {string} page page to start returning transactions for
         * @param {string} offset offset before next page is to be returned
         * @param {string} sort sort order of the transactions
         * @param {string} apikey auth0 JWT key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWalletTransactions(module: string, action: string, address: string, contractaddress: string, startblock: string, endblock: string, page: string, offset: string, sort: string, apikey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletTransactionsByAddress>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWalletTransactions(module, action, address, contractaddress, startblock, endblock, page, offset, sort, apikey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WalletTransactionsApi - factory interface
 * @export
 */
export const WalletTransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WalletTransactionsApiFp(configuration)
    return {
        /**
         * Returns a list of transactions for a wallet.
         * @summary Get all transactions for a given wallet address
         * @param {string} module request type - in this case account
         * @param {string} action request type e.g. tokentx or txlist
         * @param {string} address a wallet address
         * @param {string} contractaddress a contract address
         * @param {string} startblock block to start return transactions from
         * @param {string} endblock last block number to return transactions for
         * @param {string} page page to start returning transactions for
         * @param {string} offset offset before next page is to be returned
         * @param {string} sort sort order of the transactions
         * @param {string} apikey auth0 JWT key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWalletTransactions(module: string, action: string, address: string, contractaddress: string, startblock: string, endblock: string, page: string, offset: string, sort: string, apikey: string, options?: any): AxiosPromise<WalletTransactionsByAddress> {
            return localVarFp.getWalletTransactions(module, action, address, contractaddress, startblock, endblock, page, offset, sort, apikey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WalletTransactionsApi - object-oriented interface
 * @export
 * @class WalletTransactionsApi
 * @extends {BaseAPI}
 */
export class WalletTransactionsApi extends BaseAPI {
    /**
     * Returns a list of transactions for a wallet.
     * @summary Get all transactions for a given wallet address
     * @param {string} module request type - in this case account
     * @param {string} action request type e.g. tokentx or txlist
     * @param {string} address a wallet address
     * @param {string} contractaddress a contract address
     * @param {string} startblock block to start return transactions from
     * @param {string} endblock last block number to return transactions for
     * @param {string} page page to start returning transactions for
     * @param {string} offset offset before next page is to be returned
     * @param {string} sort sort order of the transactions
     * @param {string} apikey auth0 JWT key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletTransactionsApi
     */
    public getWalletTransactions(module: string, action: string, address: string, contractaddress: string, startblock: string, endblock: string, page: string, offset: string, sort: string, apikey: string, options?: AxiosRequestConfig) {
        return WalletTransactionsApiFp(this.configuration).getWalletTransactions(module, action, address, contractaddress, startblock, endblock, page, offset, sort, apikey, options).then((request) => request(this.axios, this.basePath));
    }
}


