import axios from "axios";

import { Configuration } from "../proxy/configuration";
import {
  TransactionApi,
  TransactionExchangeRatesApi,
  TransactionsApi,
} from "./api";
import { BalanceApi } from "./api";

const AxiosRequestClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 100000,
  headers: {
    Accept: "application/json",
    Authorization: "",
    "Content-Type": "application/json",
  },
});

//Request interceptor
AxiosRequestClient.interceptors.request.use(async function (requestConfig) {
  const accessToken = sessionStorage.getItem("accessToken");
  if (accessToken !== null) {
    const setAccessToken = "Bearer " + accessToken;
    requestConfig.headers!.Authorization = setAccessToken;
  }

  return requestConfig;
});
//Response interceptor
AxiosRequestClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const httpError = error.response.status;
    if (401 === httpError) {
      alert(
        httpError +
          " - Your session has expired/Unathorized. Redirecting back to login."
      );
      // LogoutCleanup();
      window.location.href = "/";
    } else {
      return Promise.reject(error);
    }
  }
);

const configuration = new Configuration({
  basePath:
    process.env.NODE_ENV === "development"
      ? "http://localhost:7071/api"
      : "https://fn-block-explorer-api-prod.azurewebsites.net/api",

  // "http://localhost:7071/api"
  // "https://apim-qoin-prod.azure-api.net/fn-block-explorer-api-prod"
  // "https://apim-qoin-pp.azure-api.net/fn-block-explorer-api-preprod"

  // accessToken: sessionStorage.getItem("accessToken")?.toString(),
});

export const transactionApi = (token: string) => {
  configuration.accessToken = token;
  return new TransactionApi(configuration, undefined, AxiosRequestClient);
};

export const transactionsApi = (token: string) => {
  configuration.accessToken = token;
  return new TransactionsApi(configuration, undefined, AxiosRequestClient);
};

export const transactionExchangeRateApi = (token: string) => {
  configuration.accessToken = token;
  return new TransactionExchangeRatesApi(
    configuration,
    undefined,
    AxiosRequestClient
  );
};

export const balanceApi = (token: string) => {
  configuration.accessToken = token;
  return new BalanceApi(configuration, undefined, AxiosRequestClient);
};
